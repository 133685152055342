<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-400px">
            <div>
                <p class="title">유류비 수정</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">

                <table class="tbl-light-list">
                    <colgroup>
                        <col width="130">
                        <col width="*">
                        <col width="130">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>휘발유</th>
                            <th>경유</th>
                            <th>LPG</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input type="text" v-model.trim:number="pop.gasoline" @change="pop.doChange" class="w-100per txt-center">
                            </td>
                            <td>
                                <input type="text" v-model.trim:number="pop.diesel" @change="pop.doChange" class="w-100per txt-center">
                            </td>
                            <td>
                                <input type="text" v-model.trim:number="pop.lpg" @change="pop.doChange" class="w-100per txt-center">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table txt-center">
                <button @click="pop.onClose" class="btn-default dp-inblock">닫기</button>
                <button @click="pop.doSubmit" class="btn-default dp-inblock ml-10">수정</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';

export default {
    name: 'FuelCostMangementEditPopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            info : {},
            gasoline : 0,
            diesel   : 0,
            lpg      : 0,

            doChange: () => {
                pop.gasoline += "";
                pop.diesel   += "";
                pop.lpg      += "";
                pop.gasoline = Number(pop.gasoline.replace(/[^0-9]/gi, ''));
                pop.diesel   = Number(pop.diesel.replace(/[^0-9]/gi, ''));
                pop.lpg      = Number(pop.lpg.replace(/[^0-9]/gi, ''));
            },

            doSubmit: () => {
                let params = {
                    gasoline : pop.gasoline,
                    diesel   : pop.diesel,
                    lpg      : pop.lpg
                };

                axios.post("/rest/resourceManagement/setFuelCostInfo", params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.error("저장하였습니다.");
                        pop.onClose();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch: () => {
                let params = { };

                axios.get("/rest/resourceManagement/getFuelCostInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;

                        pop.gasoline = pop.info.gasoline;
                        pop.diesel   = pop.info.diesel;
                        pop.lpg      = pop.info.lpg;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>