<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <FuelCostMangementEditPopup v-if="pop.isShow" @close="pop.hideModify"></FuelCostMangementEditPopup>
        <CarrotTitle title="유류비(연료) 관리">
            - 내 경비관리 > 유류비의 연료 항목 비용을 관리하는 페이지 입니다.<br>
            - 최근 등록한 연료(휘발유, 경유, LPG) 비용으로 반영됩니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-col w-800px">
                        <colgroup>
                            <col span="3">
                            <col width="120">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>휘발유</th>
                                <th>경유</th>
                                <th>LPG</th>
                                <th>수정일</th>
                                <th>수정</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ bbs.info.price_gasoline }}</td>
                                <td>{{ bbs.info.price_diesel }}</td>
                                <td>{{ bbs.info.price_lpg }}</td>
                                <td>{{ bbs.info.wdate }}</td>
                                <td>
                                    <span @click="pop.showModify" class="btn-view">수정</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import Swal from 'sweetalert2'
import FuelCostMangementEditPopup from '@/components/popup/ResourceManagement/FuelCostMangementEditPopup.vue'
import { useStore } from 'vuex';


export default {
    layout:"BusinessManagement",
    components: {
        FuelCostMangementEditPopup
    },
    setup() {
        const router = useRouter();
        const toast  = useToast()
        const store  = useStore();

        const pop = reactive({
            // Popup 노출 여부
            isShow: false,

            showModify : () => {
                pop.isShow = true;
            },
            hideModify : () => {
                pop.isShow = false;
                bbs.doSearch();
            }
        });
        
        const bbs = reactive({

            info : {},

            doSearch : () => {
                let params = { };

                axios.get("/rest/resourceManagement/getFuelCostInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.info = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            if( store.state.isHrAdmin == false ) {
                router.go(-1);
                Swal.fire({
                    title : '유류비(연료) 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            bbs.doSearch();
        });

        return {pop, bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>